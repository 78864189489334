<template>
  <div class="flex flex-col items-center justify-center h-full">
    <LoginForm />
  </div>
</template>

<script>
import LoginForm from '@/components/forms/LoginForm.vue';

export default {
  components: {
    LoginForm
  }
};
</script>
